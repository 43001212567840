import {Link} from "gatsby";
import React from "react";
import screenshot from "../images/FLC-Report-AtData-v4.png"

class Hero extends React.Component {
	constructor(props) {
		super(props);

		let currentURL = typeof window !== 'undefined' ? window.location.href : '';
		let urlParts = currentURL.split("/");
		let apiURL = urlParts[0] + "//" + urlParts[2];
		this.baseUrl = apiURL;
	}

	render() {
		return (
			<div className="hero layered-gradient-blue">
				<div className="grid-container grid-x align-center-middle padding-vertical" style={{maxWidth: 100 + 'rem'}}>
					<div className="small-12 large-6 cell color-white">
						<h1>A Free Health Scan for your<br/>Email Addresses!</h1>
						<p className="medium">Bouncing and toxic email addresses wreak havoc on your sender reputation, deliverability, email marketing ROI, and revenues. How many of these emails are in your files?</p>
						<p className="medium"><b>What to expect - </b>You will receive an interactive report with an overview of your file detailing:</p>
						<ul className="medium">
							<li>Valids</li>
							<li>Errors</li>
							<li>Duplicates</li>
							<li>Warnings, including the exact number of spamtraps, honeypots, frequent complainers, and other deliverable but problematic addresses</li>
						</ul>

						<Link to="/Home/Start" className="button white-button">
							Check My List
						</Link>
					</div>
					<div className="hero-image small-12 large-6 cell large-padding-left-large large-padding-right-none padding-vertical">
						<a href="/Report?id=4522&u=3178&g=5cfc47eb-c8f2-4b74-a06c-5009fe50366f" target="_blank" rel="noopener"><img src={screenshot} alt="Free List Check" className="results-img"/></a>
						<p className="small-12 large-6 text-center cell color-white">Click <a href="/Report?id=4522&u=3178&g=5cfc47eb-c8f2-4b74-a06c-5009fe50366f" target="_blank" rel="noopener" className="white-a">Here</a> for Sample Free List Check Report</p>
					</div>
					<div className="small-12 large-6 cell color-white">
						
					</div>
				</div>

				<div className="curved-separator"></div>
			</div>
		);
	}

	componentDidMount() {
		var vars = window.location.search.substring(1).split('&');
		var formData = new FormData();
		for (var i = 0; i < vars.length; i++) {
			var temp = vars[i].split('=');
			var key = decodeURIComponent(temp[0]);
			var val = decodeURIComponent(temp[1]);

			if (key.toLowerCase() === "utm_source")
				formData.append('source', val);
			else if (key.toLowerCase() === "utm_medium")
				formData.append('medium', val);
			else if (key.toLowerCase() === "utm_campaign")
				formData.append('campaign', val);
		}
		return fetch(this.baseUrl + '/Init/Register', {
			method: 'POST',
			body: formData
			})
			.then((response) => {
				return response.json();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}
}

export default Hero;
