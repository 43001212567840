import React from 'react'
import {Link} from "gatsby"
import mailbox from '../images/icons/invalids-icon-v2.svg'
import spamtrap from '../images/icons/spamtraps-icon-v2.svg'
import forced from '../images/icons/bogus-icon-v2.svg'
import resultsimg from "../images/FLC-Report-AtData-v4.png"

import Modal from 'react-responsive-modal';

class ListWarningsLong extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};
	}

	onOpenModal = () => {
		this.setState({open: true});
	};

	onCloseModal = () => {
		this.setState({open: false});
	};

	render() {
		const {open} = this.state;
		return (
			<div id="howworks" className="list-check-warnings list-check-warnings-long background-light-gray color-black padding-vertical-large">
				<div className="grid-container">
					<div className="grid-x grid-padding-x text-center">
						<div className="small-12 cell">
							<h2 className="margin-top-none">We'll scan your list for:</h2>
						</div>
					</div>

					<div className="list-check-warnings grid-x grid-padding-x text-center align-center">
						<div className="list-check-warning small-12 medium-6 large-4 cell">
							<div className="list-check-warning-item">
								<h3 className="list-warning-title">
									<img src={mailbox} alt="Mailbox" height={80}/>
									Invalids
								</h3>
								<p>High bounce rates put your email campaigns at risk, and attempting delivery on dead email addresses is a waste of system resources.</p>
							</div>
						</div>

						<div className="list-check-warning small-12 medium-6 large-4 cell">
							<div className="list-check-warning-item">
								<h3 className="list-warning-title">
									<img src={spamtrap} alt="Spamtraps" height={80}/>
									Spamtraps
								</h3>
								<p>Even one spamtrap hit can hurt your sender reputation and dramatically reduce inbox placement.</p>
							</div>
						</div>

						<div className="list-check-warning small-12 medium-6 large-4 cell">
							<div className="list-check-warning-item">
								<h3 className="list-warning-title">
									<img src={forced} alt="Fake email addresses" height={80}/>
									Bogus and Disposables
								</h3>
								<p>These fake email addresses are a waste of your marketing resources to message and a sign that your acquisition techniques need to be reexamined.</p>
							</div>
						</div>
					</div>
				</div>

				<div className="grid-container padding-top text-center grid-x align-center-middle">
					<div className="cell small-12 medium-10 large-8">
						<h4>...And more! Also including frequent complainers, language, and role accounts. <a href="https://freelistcheck.com/Report?id=4522&u=3178&g=5cfc47eb-c8f2-4b74-a06c-5009fe50366f" target="_blank" rel="noopener">See sample report</a>.</h4>
					</div>
				</div>

				<Modal open={open} onClose={this.onCloseModal} little>
					<img src={resultsimg} alt="Free List Check Results" className="results-img"/>
				</Modal>

				<div className="grid-container padding-top text-center">
					<Link to="/Home/Start" className="button blue-button">
						Get Started
					</Link>
				</div>
			</div>
		);
	}
}

export default ListWarningsLong;
