import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import SliderSection from "../components/slider-section"
import ListWarningsLong from "../components/list-warnings-long"
import BlogPosts from "../components/blog-posts"

const IndexPage = () => (
	<Layout>
		<SEO title="Home"/>

		{/* Homepage Hero */}
		<Hero />

		{/* Slider Section */}
		<SliderSection />

		{/* Blocked Section */}
		<ListWarningsLong />

		{/* Blog Posts Section */}
		<BlogPosts />
	</Layout>
)

export default IndexPage
