import React from 'react';
import featured from '../images/fa-resource-blog-featured-image-template@2x.jpg'
import risks from '../images/taking-risk-resource-blog-featured-image-template@2x.jpg'

class BlogPosts extends React.Component {
	render() {
		return (

			<div className="grid-container padding-vertical">
				<div className="grid-container text-center grid-x align-center">
					<div class="small-12 cell">
						<h3>Email List Cleaning 101</h3>
					</div>
					<div class="small-12 medium-10 large-8 cell">
						<p className="large">Want to clean, protect, and grow your email list? Here are some resources for you:</p>
					</div>
				</div>
				<div className="blog-block-container grid-x">
					<div className="small-12 medium-6 cell">
						<div className="border-container">
							<div className="image-container grid-x align-bottom" style={{backgroundImage: `url(${featured})` }}>
								<div className="title-container">
									<h2 className="blog-title">
										<a href="https://www.atdata.com/marketers-email-validation-guide/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>The Marketer's Guide to Email Validation</a>
									</h2>
								</div>
							</div>
							<div className="content-container">
								Whether you realize it or not, there's a price tag attached to every email address in your database.
								How well you manage the quality of those addresses can mean the difference between the success or
								failure of your email marketing efforts.
								<p className="small color-medium-gray margin-bottom-none"><strong>Email Validation</strong></p>
							</div>
						</div>
					</div>
					<div className="small-12 medium-6 cell">
						<div className="border-container">
							<div className="image-container grid-x align-bottom" style={{backgroundImage: `url(${risks})` }}>
								<div className="title-container">
									<h2 className="blog-title">
										<a href="https://www.atdata.com/marketers-spamtrap-guide/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>The Email Marketer's Guide to Understanding Spamtraps</a>
									</h2>
								</div>
							</div>
							<div className="content-container">
								Spamtraps are "dummy"( but fully functional and deliverable) email addresses used for the express
								purpose of attracting spam. Any messages hitting these addresses are presumed to be unsolicited bulk
								email.
								<p className="small color-medium-gray margin-bottom-none"><strong>Email Validation</strong></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default BlogPosts;
