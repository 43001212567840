import React, { Component } from "react";
import Slider from "react-slick";

import logocontacts from '../images/companies/freshaddress-customer-logo-1800-contacts-1.png'
import logoaaa from '../images/companies/freshaddress-customer-logo-aaa.png'
import logoace from '../images/companies/freshaddress-customer-logo-ace.png'
import logoae from '../images/companies/fresh-address-customer-logo-active-engagement.png'
import logoarc from '../images/companies/freshaddres-customer-logo-american-red-cross.png'
import logoamnesty from '../images/companies/AmnestyInternational.png'
import logobig5 from '../images/companies/Big5Sporting.png'
import logobissell from '../images/companies/bissell-logo.png'
import logoedible from '../images/companies/freshaddress-customer-logo-edible-arrangements.png'
import logoedf from '../images/companies/EDF-New.jpg'
import logoharvard from '../images/companies/Harvard-Pilgrim.png'
import logohomeaway from '../images/companies/homeaway-2-logo-svg-vector.png'
import logohonda from '../images/companies/freshaddress-customer-logo-honda.svg'
import logokenneth from '../images/companies/kenneth-cole-foc.png'
import logonatgeo from '../images/companies/National_Geographic_Logo_2016.png'
import logonautilis from '../images/companies/freshaddress-customer-logo-nautilus.png'
import logoperkins from '../images/companies/Perkins-School.png'
import logopetco from '../images/companies/petco.png'
import logoprincess from '../images/companies/Princess_Cruises-Logo.png'
import logostanley from '../images/companies/freshaddress-customer-logo-stanley.svg'
import logostaples from '../images/companies/NewStaples.png'
import logotumi from '../images/companies/Tumi.png'
import logounicef from '../images/companies/freshaddress-customer-logo-unicef.png'
import logouom from '../images/companies/UOM.png'
import logoviking from '../images/companies/freshaddress-customer-logo-viking.png'
import logowcs from '../images/companies/WCS_LOGOTYPE.png'
import logozillow from '../images/companies/freshaddress-custom-logo-zillow-2.png'

export default class SimpleSlider extends Component {
	render() {
		const settings = {
			dots: false,
			arrows: false,
			slidesToShow: 6,
			slidesToScroll: 6,
			infinite: true,
			autoplay: true,
			autoplayspeed: 6000,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						autoplayspeed: 3000,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						autoplayspeed: 2000,
					}
				}
			]
		};
		return (
			<div className="company-slider">
				<Slider {...settings}>
					<div>
						<img src={logocontacts} alt="1-800 Contacts" />
					</div>
					<div>
						<img src={logoaaa} alt="AAA" />
					</div>
					<div>
						<img src={logoace} alt="Ace Hardware" />
					</div>
					<div>
						<img src={logoae} alt="" />
					</div>
					<div>
						<img src={logoarc} alt="" />
					</div>
					<div>
						<img src={logoamnesty} alt="" />
					</div>
					<div>
						<img src={logobig5} alt="" />
					</div>
					<div>
						<img src={logobissell} alt="" />
					</div>
					<div>
						<img src={logoedible} alt="Edible Arrangements" />
					</div>
					<div>
						<img src={logoedf} alt="Environment Defense Fund" />
					</div>
					<div>
						<img src={logoharvard} alt="Harvard Pilgrim" />
					</div>
					<div>
						<img src={logohomeaway} alt="Home Away" />
					</div>
					<div>
						<img src={logohonda} className="company-svg" alt="Honda" />
					</div>
					<div>
						<img src={logokenneth} alt="Kenneth Cole" />
					</div>
					<div>
						<img src={logonatgeo} alt="National Geographic" />
					</div>
					<div>
						<img src={logonautilis} alt="Nautilis" />
					</div>
					<div>
						<img src={logoperkins} alt="Perkins School for the Blind" />
					</div>
					<div>
						<img src={logopetco} alt="Petco" />
					</div>
					<div>
						<img src={logoprincess} alt="Princess Cruises" />
					</div>
					<div>
						<img src={logostanley} className="company-svg" alt="Stanley Bostich" />
					</div>
					<div>
						<img src={logostaples} alt="Staples" />
					</div>
					<div>
						<img src={logotumi} alt="Tumi" />
					</div>
					<div>
						<img src={logounicef} alt="Unicef" />
					</div>
					<div>
						<img src={logouom} alt="University of Michigan" />
					</div>
					<div>
						<img src={logoviking} alt="Viking River Cruises" />
					</div>
					<div>
						<img src={logowcs} alt="Wildlife Conservation Society" />
					</div>
					<div>
						<img src={logozillow} alt="Zillow" />
					</div>
				</Slider>
			</div>
		);
	}
}
