import LogoSlider from "./logo-slider";
import React from "react";

class SliderSection extends React.Component {
	render() {
		return (
			<div className="trusted-container text-center padding-vertical">
				<div className="grid-container">
					<h3>Trusted By the World's Leading Brands</h3>
					<div>
						<LogoSlider />
					</div>
					<div className="margin-top">
						<a href="https://atdata.com" className="arrow">Experience the AtData Difference</a>
					</div>
				</div>
			</div>
		);
	}
}

export default SliderSection;
